import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useSelector} from 'react-redux'
import Related from './related.js'
import { Link } from 'react-router-dom';
import { commonHeaders, searchBuildings } from '../../helpers/api_routes.js';

const axios = require('axios')

const SearchResultShowTabs = (props) => {

	const related_listings = props.listing.related_listings;

	const [results,
        setResults] = useState([])
    const [error,
		setError] = useState('')
		
    const user = useSelector(state => state.usersStatus)

    //combine common headers with users session id
    const headers = {
        ...commonHeaders,
        ...{
            "Adm-Session-ID": user.sessionId
        }
    }

    const fetchRelated = () => {
        axios
            .get(`${searchBuildings}/${props.listing.building_id}`, {headers: headers})
            .then(res => {
                if (res.data) {
                    setResults(res.data)
                }
            })
            .catch(err => {
                setError(err.message)
                console.log(error)
            })
	}
	
	useEffect(() => {
		fetchRelated()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	let initialTab;

	const handleInitialTab = () => {
		if (results.rentals.length < 1 && results.sales.length > 0) {
			initialTab = 1
		} else {
			initialTab = 0
		}
	}

	useEffect(() => {
		if (results.length > 0) {
			handleInitialTab()
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [results])

	if (related_listings.length > 0) {
		return (

			<Tabs className="tabs" defaultIndex={initialTab}>

	          <div className="subheading">LISTINGS IN THE BUILDING</div>

	          <TabList className="tabs__header">
	            <Tab className="tab js_tab" id="tab_rental">For Rent ({results.rentals && results.rentals.length})</Tab>
	            <Tab className="tab js_tab" id="tab_sale">For Sale ({results.sales && results.sales.length})</Tab>
	          </TabList>
	          <div className="tabs__body">
	          

	                <TabPanel className="tab__content js_tab_content" id="tab_rental_content">

						{ results.rentals && results.rentals.map(
		                    (item, i) => 
			                    <Link to={`/listing/${item.advertisement_id}`} key={i}>
			                    	<Related listing = {item} key={i} />
			                    </Link> 
		                    )
		                }

	                </TabPanel>
	          
	                <TabPanel className="tab__content js_tab_content" id="tab_sale_content">

		                { results.sales && results.sales.map(
		                    (item, i) => 
			                    <Link to={`/listing/${item.advertisement_id}`} key={i}>
			                    	<Related listing = {item} key={i} />
			                    </Link> 
		                    )
		                }

	                </TabPanel>

	          </div>
	        </Tabs>

		)
	} else {
		return null
	}

	
	
}

export default SearchResultShowTabs;