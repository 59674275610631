//libraries
import React from 'react';

function Features (props) {

	var outdoorInfo = props.listing.outdoor_space_info

    return ( 

		<ul>
			{ props.listing.building_type &&
				<li>{props.listing.building_type}</li>
			}
			{ outdoorInfo &&
				<li>{outdoorInfo}</li>
			}
			{ props.listing.pet_policy && 
				<li>{props.listing.pet_policy}</li>
			}
		</ul>

    );

}

export default Features;
