import React from 'react';

const Days = (props) => {

	if (props.listing.dom) {
 		return (
			<div>{props.listing.dom}</div>
		)
	} else {
		return <div>0</div>
	}
}


export default Days;