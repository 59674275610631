import React from 'react';

 const Contacts = (props) => {

 		let contacts = Object.values(props.listing.viewing_contacts).map(obj => obj)
 		let mailto = Object.values(contacts).map(obj => obj.email)
 		mailto = `mailto:${mailto}`

		if (props.listing.viewing_contacts) {
			return (
				<React.Fragment>
					{Object.values(contacts).map((obj, i) =>
					<div className="subsection--padding" key={i}> 
						<div className="semibold">{obj.name}</div>
						<div>P: {obj.phone_number}</div>
						<div>E: <a href={mailto} className="text--primary">{obj.email}</a></div>
					</div>	
					)}
				</React.Fragment>
			)
		} else {
			return <div>No contacts</div>
		}

		
	}


export default Contacts;