import usersStatusReducer from './users_status.js';
import searchResultsReducer from './search_results.js';
import searchPhraseReducer from './search_phrase.js';
//import addUrlsReducer from './add_urls.js';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
  usersStatus: usersStatusReducer,
  searchResults: searchResultsReducer,
  searchPhrase: searchPhraseReducer,
 // addUrls: addUrlsReducer
});

export default allReducers;
