import React, {useState, useContext} from 'react';
import iconSearch from '../../images/icons/search-icon-2.svg';
import iconSettings from '../../images/icons/nav/icon-gear.svg';
import SearchFilters from '../search-filters/search_filters.js';  
import SearchSettings from '../search-settings/search_settings.js';  
import {FiltersContext} from '../../helpers/filters_context.js'


function SearchField() {

  // import filters 
  const filters = useContext(FiltersContext);
  const [show, setShow] = useState(false);
  const [settings, setSettings] = useState(false);
  
  const showModal = (e) => {
    e.preventDefault();
    setShow(true)
  }

  const showResults = (e) => {
    e.preventDefault();
    setShow(false)
    filters.setSearchState(filters.advice.title)
  }

  const showSettings = (e) => {
      e.preventDefault();
      setSettings(true)
  }

  const closeSettings = (e) => {
      e.preventDefault();
      setSettings(false)
  }

  const setPhraseVal = (e) => {
      e.preventDefault()
      filters.setPhrase(e.target.value)
  }

  return (

    <React.Fragment>

      <div className="layout--main">
        <div className="search-bar" data-aos="fade-left">
          <div className="search-bar__main">
            <form className="form--search" onSubmit={(e) => {e.preventDefault()}}>
             <input type="search" placeholder="Search" onChange={setPhraseVal} value={filters.phrase} />
             <div className="form--search__btn"><img src={iconSearch} alt="" /></div>
            </form>
          </div>
          <div className="search-bar__bottom">
            <button className="btn btn--primary" onClick={showModal}>Filters</button>
            {/*<button className="btn btn--white">Last search</button>*/}
            <div className="icon" value={true} onClick={showSettings}><img src={iconSettings} alt="Settings" /></div>
          </div>
        </div>
      </div>

      <SearchFilters show={show} handleClose={showResults} count={filters.advice.data && filters.advice.data.count} />
      <SearchSettings show={settings} handleClose={closeSettings} />

    </React.Fragment>
  );
}

export default SearchField;
