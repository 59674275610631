//libraries
import React, {useContext, useState, useEffect} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'
import NumberFormat from 'react-number-format';

const FilterPrice = (props) => {  

	const filters = useContext(FiltersContext)
	const [min, setMin] = useState('')
	const [max, setMax] = useState('')

	useEffect(() => {
		if (filters.minPrice > 0) {
			setMin(filters.minPrice)
		}
		if (filters.maxPrice > 0) {
			setMax(filters.maxPrice)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])

	useEffect(() => {
		if (filters.minPrice === '') {
			setMin('')
		}
		if (filters.maxPrice === '') {
			setMax('')
		}
	}, [filters])

	const setPrice = () => {
		filters.setMinPrice(min)
		filters.setMaxPrice(max)
	}

	useEffect(() => {
		if (min || max) {
			setPrice()	
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [min, max])

	return (
		<React.Fragment>
			<div className="subheading--big">Price range</div>
			 <section className="subsection">
			    <div className="form__field form__field--margin">
			      <div className="input--price-range__wrap">
			        <label className="label input--price-range__label">from</label>
			       	<NumberFormat type="tel" className="input--price-range" placeholder="$20,000" value={min} thousandSeparator={true} prefix={'$'} onValueChange={(values) => {
					    const {value} = values;
					    setMin(value)
					}}/>
			      </div>
			    </div>
			    <div className="form__field form__field--margin">
			      <div className="input--price-range__wrap">
			        <label className="label input--price-range__label">to</label>
			        <NumberFormat type="tel" className="input--price-range" placeholder="$20,000" value={max} thousandSeparator={true} prefix={'$'} onValueChange={(values) => {
					    const {value} = values;
					    setMax(value)
					}}/>
			      </div>
			    </div>
			</section>
		</React.Fragment>
	)

}

export default FilterPrice;


 