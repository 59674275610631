import React, { useContext } from 'react';
import close from '../../images/icons/close.svg';
import FilterBuildingType from './filter_building_type.js';
import FilterNeigbhorhood from './filter_neighborhood.js';
import FilterPrice from './filter_price.js';
import FilterRoomType from './filter_room_type.js';
import FilterBathrooms from './filter_bathroom.js';
import FilterPets from './filter_pets.js';
import AdType from './ad_type.js';
import {FiltersContext} from '../../helpers/filters_context.js'
import NumberFormat from 'react-number-format';


const SearchFilters = (props) => {  

  const filters = useContext(FiltersContext);
  const showHideClassName = props.show ? "modal modal--show" : "modal modal--hide";

  return (
    <div className={showHideClassName}>
      <div className="modal__body">
        <section>
          <section className="layout--main">
            <header className="filters__header">
              <div className="link" onClick={filters.resetFilters}>Reset all</div>
              <div className="close modal__close" onClick={props.handleClose}>
                <img src={close} alt='Close btn' />
              </div>
            </header>

            <section className="section section--border section--padded bold">Search / Filters</section>
            <section className="section section--border section--padded">
                <AdType />
             </section>
             <section className="section section--border section--padded">
                <FilterRoomType />
             </section>
             <section className="section section--border section--padded">
                <FilterBathrooms />
             </section>
             <section className="section section--border section--padded">
                <FilterPets />
             </section>
             <section className="section section--border section--padded">
                <FilterBuildingType />
             </section>
             <section className="section section--border section--padded">
                <FilterNeigbhorhood />
             </section>
             <section className="section section--border section--padded">
                <FilterPrice />
             </section>
          </section>

          <div className="sticky-btn">
            <div className="layout--main">
              <button className="btn btn--primary btn--full--big" onClick={props.handleClose}>Show <NumberFormat value={filters.advice.count} displayType={'text'} thousandSeparator={true} /> results</button>
            </div>
          </div>
        </section>
        
      </div> 
    </div>
  )
}

export default SearchFilters;
