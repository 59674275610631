import React from 'react';
import NumberFormat from 'react-number-format'

const Price = (props) => {

    const listing = props.listing
    
    return (
     <div className="bold text--xlarge"><NumberFormat value={listing.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
    )
  }


export default Price;