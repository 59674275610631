import React from 'react';

const Description = (props) =>  {

		const listing = props.listing

		if (listing.description !== null) {
			return (
				<div className="subsection">
		            <div className="subheading">description</div>
		            {listing.description}
		        </div> 
			)
		} else {
			return <div>No Description</div>
		}
		
}

export default Description;