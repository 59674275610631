//libraries
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import iconArrowDown from '../images/icons/icon-arrow-down.svg';
import iconArrowLeft from '../images/icons/nav/icon-arrow-left.svg';
//import { commonHeaders } from '../helpers/api_routes.js'
import {ViewContext} from '../helpers/view_context.js'

function Nav (props) {

  const viewContext = useContext(ViewContext);

  //const {history} = props


  const firstName = 'S';
  const lastName = 'O';

  return (
    <nav className="nav">
      <div className="layout--main">

      { viewContext.nav ?
        <div className="nav__wrap" data-aos="fade-right">
            <div className="nav__section nav__section--left">
              <Link to="/dashboard">
                <div className="nav__link--main">
                  <span className="nav-user">
                    <span>{firstName}</span>
                    <span>{lastName}</span>
                  </span>
                  <div className="nav__link-icon">
                    <img src={iconArrowDown} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="nav__section nav__section--right">
            </div>
        </div>

        :

        <div className="nav__wrap" data-aos="fade-left">
            <div className="nav__section nav__section--left">
              <Link to="/" className="icon icon--1x">
                <img src={iconArrowLeft} alt="back"  />
              </Link>
            </div>
            <div className="nav__section nav__section--right">
            </div>
        </div>

        }

      </div>
    </nav>
  );

}

export default Nav;
