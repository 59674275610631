//libraries
import React from 'react';
import SearchResultShowTabs from '../search-result-show/search_result_show_tabs.js'
import Description from '../search-result-show/search_result_show_description.js'
import Images from '../search-result-show/search_result_show_images.js'
import Tags from '../search-result-show/search_result_show_tags.js'
import Address from '../search-result-show/search_result_show_address.js'
import Price from '../search-result-show/search_result_show_price.js'
import PriceChange from '../search-result-show/search_result_show_price_change.js'
import ItemList from '../search-result-show/search_result_show_items.js'
import About from '../search-result-show/search_result_show_about.js'
import Period from '../search-result-show/search_result_show_period.js'
import Amenities from '../search-result-show/search_result_show_amenities.js'
import BuildingAmenities from '../search-result-show/search_result_show_building_amenities.js'
import Available from '../search-result-show/search_result_show_available.js'
import Days from '../search-result-show/search_result_show_days.js'
import Contacts from '../search-result-show/search_result_show_contacts.js'
import CompanyInfo from '../search-result-show/search_result_show_company_info.js'
import ManagementInfo from '../search-result-show/search_result_show_management_info.js'
import AccessInfo from '../search-result-show/search_result_show_access_info.js'
import Incentive from '../search-result-show/search_result_show_incentive.js'


const SearchResultShowListing = (props) => {
  
const listing = props.listing.data

if (listing) {
  return (
  <div className="search-result-show" key={listing.advertisement_id} data-aos="fade-in">
      <header className="single-result-header">

        <Images listing = {listing} galleryView={true} />
        <Tags listing = {listing} />
        <div className="single-result-header__btns"></div>
        
      </header>

      <div className="layout--main">
        <section className="section section--border" data-aos="fade-in">
          <ItemList listing = {listing} />
          <Address listing = {listing} />
        </section>

        <section className="section section--border" data-aos="fade-in">
          <div className="grid">
            
            <div className="cell cell--2--small">
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">condo</div>
                <Price listing = {listing} />
              </div> 
            </div>
            <div className="cell cell--2--small">
              <div className="subsection subsection--right" data-aos="fade-in">
                <div className="subheading">period</div>
                <Period listing = {listing} />
              </div> 
            </div>
          </div>

        </section>

        <section className="section section--border" data-aos="fade-in">
          <div className="grid">
            
            <div className="cell cell--4--small">
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">price change</div>
                <PriceChange listing = {listing} />
              </div> 
            </div>
          </div>

        </section>

        <section className="section section--border">
          
          <div className="grid">
            
            <div className="cell cell--2--small">
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">days on market</div>
                <div className="semibold text--medium"><Days listing = {listing} /></div>
              </div> 
            </div>
            <div className="cell cell--2--small">
              <div className="subsection subsection--right" data-aos="fade-in">
                <div className="subheading">available</div>
                <Available listing={listing} />
              </div> 
            </div>
          </div>

        </section>

        <section className="section section--border">
          
          <div className="grid">          
            <div className="cell cell--4--small">
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">incentive</div>
                <Incentive listing = {listing} />
             
              </div> 
            </div>
          </div>

        </section>

        <section className="section section--border">
          
          <div className="grid">          
            <div className="cell cell--4--small">
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">about</div>
                <About listing = {listing} />
              </div> 
            </div>
          </div>

        </section>

        <section className="section section--border">
          
          <div className="grid">          
            <div className="cell cell--4--small">
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">Building amenities</div>
                  <BuildingAmenities listing = {listing} />
                </div>
            </div>
          </div>

        </section>

        <section className="section section--border">
          
          <div className="grid">          
            <div className="cell cell--4--small">
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">Apartment amenities</div>
                  <Amenities listing = {listing} />
                </div>
            </div>
          </div>

        </section>

        <section className="section section--border">
          
          <div className="grid">          
            <div className="cell cell--4--small" data-aos="fade-in">
              <Description listing = {listing}/>
            </div>
          </div>

        </section>

        <section className="section section--border">
          
          <div className="grid">          
            <div className="cell cell--4--small">
              <div className="subsection subsection--padding" data-aos="fade-in">
                <div className="subheading">company info</div>
                <CompanyInfo listing = {listing} />
              </div>
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">management info</div>
                <ManagementInfo listing = {listing} />
              </div>
            </div>
          </div>

        </section>


        <section className="section section--border">
          
          <div className="grid">          
            <div className="cell cell--4--small">
              <div className="subsection subsection--padding" data-aos="fade-in">
                <div className="subheading">access info</div>
                <AccessInfo listing = {listing} />
              </div>
              <div className="subsection" data-aos="fade-in">
                <div className="subheading">CONTACTS</div>
                <Contacts listing = {listing} />
              </div>
            </div>
          </div>

        </section>

        <SearchResultShowTabs listing={listing} />

      </div>

  </div>
)
}

else {
  return null
}

}

export default SearchResultShowListing;










