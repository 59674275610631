// eslint-disable-next-line no-unused-vars
const version = "v1";

export const mainUrl  = `${process.env.REACT_APP_MAIN_URL}`;
export const authRootUrl     = `${process.env.REACT_APP_AUTH_ROOT_URL}`;
const rootUrl         = `${process.env.REACT_APP_ROOT_URL}`;
export const mediaUrl = `${process.env.REACT_APP_MEDIA_URL}`;

export const env = {
  staging: `${process.env.REACT_APP_ADM_APP_AUTH}`
}; 

//console.log(env.staging);
//console.log(mainUrl);
//console.log(authRootUrl);
//console.log(rootUrl);
//console.log(mediaUrl);

//console.log("STARI");
//console.log( `https://app-api.pocketbroker.com/${version}/plist` );
//console.log( `https://auth-api-stage.pocketbroker.com/${version}` );
//console.log( `https://agent-api-stage.pocketbroker.com/${version}` );
//console.log( `https://media-stage.pocketbroker.co/transcode/wpg` );



// SESSION
export const authHeaders = {
    "Adm-App-Auth": `${env.staging}`,
    "Content-Type": "application/json",
    "X-Requested-With": 'XMLHttpRequest',
}
export const authUrl = `${authRootUrl}/login-google`;

// SEARCH
export const commonHeaders = {
    "Adm-App-Auth": `${env.staging}`
}

// buildings
export const searchBuildings = `${rootUrl}/search-buildings`;
export const searchListings = `${rootUrl}/search-listings`;

// search from options 

export const searchOptions = `${rootUrl}/search-form-options`

export const listingsAdvice = `${rootUrl}/search-listings-advice`


