// libraries
import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { addUserAction } from './actions/users_status';

// pages and components
import Nav from './components/nav.js';
//import Footer from './components/footer.js';
import SignIn from './components/session/sign_in.js';

// pages
import Home from './pages/home.js';
import Dashboard from './pages/dashboard.js';
import SearchResultShow from './pages/search-result-show.js';
import PageOne from './pages/page_one.js';
import PageTwo from './pages/page_two.js';
import PageThree from './pages/page_three.js';
//import SearchResultShow from './pages/search_result_show.js';

//helpers
import {mainUrl} from './helpers/api_routes.js';
import { getCurrentUser } from './helpers/current_user.js';
import {ViewProvider} from './helpers/view_context.js'
import {FiltersProvider} from './helpers/filters_context.js';
import {SearchProvider} from './helpers/search_context/search_context.js';

import AOS from 'aos';
import 'aos/dist/aos.css';


// styles
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiIsReady: false,             // before login check if there is some connection to the api
      currentUser: getCurrentUser(), // after login, will get from local storage - default is false 
    };

    // happens after login
    this.isSuccess = this.isSuccess.bind(this);
    this.signInFailure = this.signInFailure.bind(this);
  };

  componentDidMount() {
    // check if there is some api connection - is the api ready?
    fetch(mainUrl, {
      method: "OPTIONS",
      headers: {
        "Adm-App-Auth": process.env.REACT_APP_ADM_APP_AUTH,
      },
    }).then(res => {
      if (res.status === '401') {
        this.setState({apiIsReady: false});
      } else {
        this.setState({apiIsReady: true});
      }
    });

    AOS.init({
      duration :300,
      offset: -50,
      mirror: true
    })

    // TODO code below can fetch data from /session-info
    /*
    if (this.state.currentUser) {
      console.log("+++++")
      const headers = {...commonHeaders , ...{"Adm-Session-ID": this.state.currentUser.sessionId} }
      axios.get(`${authRootUrl}/session-info`, {headers: headers}).then(function (response) {
        console.log("-----")
        console.log(response)
      })
    }
    */

    this.props.addUser();

  }

  isSuccess() {
    // get current user from localStorage
    this.setState({currentUser: getCurrentUser() });
  }

  signInFailure(response) {
    console.log("error =>", response);
  }

  render() {
    // if there is an currentUser in the local storage run the app
    if (this.state.currentUser) {

      return (

        <Router>

        <FiltersProvider user={this.state.currentUser}>
        <SearchProvider>
        <div className="App">
        <ViewProvider>
        <header className="App-header" data-aos="fade-down">

        <Nav  user={this.state.currentUser} view={this.props.view}/>


        </header>

        <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/dashboard" exact render={(props) => <Dashboard {...props} isSuccess={this.isSuccess} />} />
          <Route path="/listing/:id" exact render={(props) => <SearchResultShow {...props} user={this.state.currentUser} />}>  
          </Route>
          <Route path="/page_one" component={PageOne} />
          <Route path="/page_two" component={PageTwo} />
          <Route path="/page_three" component={PageThree} />
          </Switch>



          {/*<Footer />*/}
          </ViewProvider>
          </div>
          </SearchProvider>
          </FiltersProvider>
          </Router>


        );
        } else {
          // if there is no currentUser show the login button
          return(
            <div>
            <div className="login-header">
            <div className="layout--main">
            <h1 className="white">Stage Office</h1>
            </div>
            </div>
            <section className="login">

            <div className="layout--main">
            <header className="header--main">
            <h2 className="heading-2">
            Welcome back
            </h2>
            </header> 
            <div className="btn--google">
            {this.state.apiIsReady ? (
              <SignIn 
              apiData = {this.state.apiIsReady}
              isSuccess = {this.isSuccess}            
              signInFailure = {this.signInFailure} />
            ) : (null) }
            </div>
            </div>
            </section>
            </div>
        );
        }
    }
  }


  // some redux functions that connect this component with redux sotre
  function mapStateToProps(state, props) {
    return {
      addUser: state.currentUser
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    addUser(currentUser) { dispatch(addUserAction(currentUser)) }
  })

  // usually ract uses:
  //export default App;
  // but bc of redux:
  export default connect(mapStateToProps, mapDispatchToProps)(App);

