//libraries
import React from 'react';
import iconKey from '../../../images/icons/search-result/icon-guy-key.svg';
import iconPet from '../../../images/icons/search-result/icon-pet.svg';

function Icons (props) {
    
    return ( 

		<div className="icon-wrap--center">
			{props.listing.building_type === "Full Time Doorman" && 
				<img src={iconKey} alt="Search result icon" className="icon--spacing" />
			}
			{ props.listing.pet_policy !== "No Pets Allowed" && 
				<img src={iconPet} alt="Search result icon" className="icon--spacing" />
			}
		</div>

    );

}

export default Icons;
