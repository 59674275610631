export function getCurrentUser() {
  // get stuff from local storage
  const sessionId = localStorage.getItem('user_session_id');
  const userId = localStorage.getItem('user_id');
  const currentUser = {
    sessionId: sessionId,
    userId: userId,
    lastName: localStorage.getItem('user_last_name'),
    firstName: localStorage.getItem('user_first_name'),
    sessionTimeCreated: localStorage.getItem('user_session_time_created'),
    gender: localStorage.getItem('user_gender'),
    phone: localStorage.getItem('user_phone'),
    enabled: localStorage.getItem('user_enabled'),
    yearBorn: localStorage.getItem('user_year_born'),
    roles: localStorage.getItem('user_roles'),
  };

  if (sessionId && userId) {
    return currentUser; 
  } else {
    return false; 
  };
};

export function addCurrentUser(json) {
  // add stuff from local storage
  localStorage.setItem('user_session_id', json.data.session.user_session_id);
  localStorage.setItem('user_session_time_created', json.data.session.time_created);
  localStorage.setItem('user_first_name', json.data.user.first_name);
  localStorage.setItem('user_last_name', json.data.user.last_name);
  localStorage.setItem('user_gender', json.data.user.gender);
  localStorage.setItem('user_phone', json.data.user.phone);
  localStorage.setItem('user_id', json.data.user.user_id);
  localStorage.setItem('user_enabled', json.data.user.enabled);
  localStorage.setItem('user_year_born', json.data.user.year_born);
  localStorage.setItem('user_roles', json.data.user.roles);

  if (json.data.session.user_session_id && json.data.user.user_id) {
    return getCurrentUser();  
  } else {
    return false; 
  }
};

export function removeCurrentUser() {
  // remove stuff from local storage
  localStorage.removeItem('user_session_id');
  localStorage.removeItem('user_session_id');
  localStorage.removeItem('user_first_name');
  localStorage.removeItem('user_last_name');
  localStorage.removeItem('user_gender');
  localStorage.removeItem('user_phone');
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_enabled');
  localStorage.removeItem('user_year_born');
  localStorage.removeItem('user_roles');

  // currentUser in redux will be set to false
  return false;
};
