import React from 'react';
//import { GoogleLogout } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { removeUserAction } from '../../actions/users_status.js'

// helpers
//import { googleClientId } from '../../helpers/variables.js';
import { removeCurrentUser } from '../../helpers/current_user.js';

function SignOut(props) {
  // redux connection
  const dispatch = useDispatch();

  return(
    <div onClick={() => {
      console.log("cooon")
      dispatch(removeUserAction( removeCurrentUser() ));
      props.isSuccess();
    } }>
    <p> Logout </p>

    </div>
  );

  //TODO does the api have an signout
  /*
  return (
    <div>
    <GoogleLogout
    clientId={googleClientId}
    buttonText="Logout"
    onLogoutSuccess={ () => { 
        dispatch(removeUserAction( removeCurrentUser() ));
        props.isSuccess();
      }
    }
    onFailure={() => {
    }
    }/>
  </div>
 );
 */
}

export default SignOut;
