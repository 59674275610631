import React, {useContext, useEffect} from 'react';
import SearchResults from '../components/search/search_results.js';
import SearchField from '../components/search/search_field.js';

import {ViewContext} from '../helpers/view_context.js';
import {FiltersContext} from '../helpers/filters_context.js'


function Home (props) {

   const viewContext = useContext(ViewContext);
   const filterContext = useContext(FiltersContext);

  useEffect(() => {
    viewContext.setNav(true)
  }, [viewContext]);
 
  return(

      
          <div>
            <SearchField />
            <SearchResults key={filterContext.randomNumber} phrase={filterContext.phrase} />
          </div>
      
  );

}

export default Home;
