import React from 'react';
import NumberFormat from 'react-number-format';

const Related = (props) => {

    return (

        <div className="related-listing" data-aos="fade-in">
            <div className="related-listing__header">
                <div className="item-list item-list--small">
                    {props.listing.unit && <span>{props.listing.unit}</span>}
                    {props.listing.bedrooms !== 0 && <span>{props.listing.bedrooms + ' bed'}</span>}
                    {props.listing.bathrooms !== 0 && <span>{props.listing.bathrooms + ' bath'}</span>}
                    {props.listing.size !== 0 && <span>{props.listing.size + ' sqft'}</span>}
                </div>
                <div className="bold related-listing__price"><NumberFormat
                    value={props.listing.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}/></div>
            </div>
            <div className="related-listing__body">
                <div className="text--gray">{props.listing.access_type_info}</div>
                <div>DOM {props.listing.dom}</div>
            </div>
            <div className="related-listing__btns">
                {props.listing.has_floorplan !== 0 && <div className="btn--square btn--blue--dark">floorplan</div>
}
                {props.listing.photos_count !== 0 && <div className="btn--square">photos</div>
}
            </div>
        </div>

    )
}

export default Related;