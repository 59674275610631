//libraries
import React, {useState} from 'react';
import { mediaUrl } from '../../../helpers/api_routes.js';
import ImgPlaceholder from '../../img_placeholder.js'

function Image (props) {
	const [error, setError] = useState(false)
	let src = `${mediaUrl}/${props.listing.featured_photo}`

	if (!error) {
		return ( 
		    <React.Fragment>
			    { props.listing.featured_photo ?
			      <img src={src} alt="Search result" onError={() => setError(true)} data-aos="fade-in"/> : 
			      <ImgPlaceholder />
			    }
		    </React.Fragment>
		);
	} else {
		return <ImgPlaceholder />
	}
    
   

}

export default Image;
