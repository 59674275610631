import React from 'react';

const Tags = (props) => {

    const listing = props.listing
    var has_360 = listing[360]

    return (
      <div className="single-result-header__tags tags">
        {has_360.length > 0 &&
            <span className="tags__tag tags__tag--blue">360 view</span>
        }
        {listing.has_floorplan &&
          <span className="tags__tag tags__tag--turquoise">floorplan</span>
        }
        {listing.nofee !== 0 &&
            <span className="tags__tag tags__tag--green">no fee</span>
        }
      </div>
    )
  }


export default Tags;