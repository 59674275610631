//libraries
import React from 'react';

//import iconAgent from '../../images/icons/search-result/icon-agent.svg';
//import { formatBuildingState } from '../../helpers/format_building_state.js';

// components 
import Tags from './search_result_tags.js';
import Image from './search_result_img.js';
import Info from './search_result_info.js';
import Price from './search_result_price.js';
import Address from './search_result_address.js';
import Days from './search_result_days.js';
import Icons from './search_result_icons.js';
import PriceChange from './search_result_price_change.js';

function SearchResultSimple (props) {

    // get current date and listing date
    //const time = props.listing.data.timeCreated*1000;

    // see if the date created is same as current date
    //const listedToday = isToday(time);
    
    return ( 

             <div className="search-result">

                <div className="search-result__img">
                  <Tags listing={props.listing} />
                  <Image listing={props.listing} />
                  <div className="search-result__overlay">
                    <Address listing={props.listing} />
                    <div>
                      <Days listing={props.listing} />
                      <span> days</span>
                    </div>
                  </div>
                </div>

                <div className="search-result__body">

                  <div className="info-row">
                    <Info listing={props.listing} />
                    <div className="info-row__price text--medium">
                      <PriceChange listing={props.listing} simple={true} />
                      <Price listing={props.listing} />
                    </div>
                  </div>

                  <div className="text--black">{/*props.listing.data.address*/}</div>

                  <div className="search-result__icons">
                   <Icons listing={props.listing} />
                  </div>

                </div>
              </div>
    

    );

}

export default SearchResultSimple;
