import React, {createContext} from 'react';

export const SearchContext = createContext();

export const SearchProvider = (props) => {

	return(
		<SearchContext.Provider value={[]}>
			{props.children}
		</SearchContext.Provider>
	);

}