//libraries
import React, {useContext} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'

const FilterPets = () => {

	const filters = useContext(FiltersContext)
	const pets = filters.options.pets

	const handleCheck = (e) => {
		e.preventDefault()
		if (e.target.value !== filters.pets) {
			filters.setPets(e.target.value)
		} 
		if (e.target.value === filters.pets) {
			filters.setPets([])
		}
	}

	if (pets) {
		return (

			<React.Fragment>
				<div className="subheading--big">Pet policy</div>
				 <section className="subsection">

				 		{pets.map((obj, i) =>  
							<div className="input--radio__wrap" key={i}>
								<input type="radio" id={`pets_${i}`} value={obj} className="input--radio" onChange={handleCheck} checked={filters.pets === obj} />
								<label htmlFor={`pets_${i}`}>{obj}</label>
							</div>
						)}


				</section> 

			</React.Fragment>
		)
	} else {
		return null
	}

}

export default FilterPets;


 