//libraries
import React, {useContext, useState, useEffect}  from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'
import BuildingTypeInput from './building_type_input.js'

const FilterBuildingType = (props) => { 

	const filters = useContext(FiltersContext);
	const [allTypes, setAllTypes] = useState(false); 

	// fetched search options
	const building_type = filters.options.building_type

	const handleAll = (e) => {
		filters.setBuildingTypes([]);
	}

	useEffect(() => {
		if (filters.buildingTypes.length === 0) {
			setAllTypes(true)
		} 
		if (filters.buildingTypes.length > 0) {
			setAllTypes(false)
		}
	}, [filters.buildingTypes]);

	if (building_type) {
		return (
			<React.Fragment>
				<div className="subheading--big">Building type</div>
				 <section className="subsection"> 
				 	<div className="input--checkbox__wrap">
				        <input type="checkbox" id="all" value="all" className="input--checkbox" onChange={handleAll} checked={allTypes}/>
				        <label htmlFor="all">All</label>
				    </div>
				    {building_type.map((obj, i) => 
						<BuildingTypeInput value={obj} id={i} key={i} />
					)}
				</section> 

			</React.Fragment>
		)	
	} else {
		return null
	}
	

}

export default FilterBuildingType;


 