import React from 'react';

const Period = (props) => {

    const listing = props.listing

    if (listing.open_house_info) {
    	return (
	     <div className="semibold text--primary text--medium">{listing.open_house_info}</div>
	    )
    } else {
    	return <div>N/A</div>
    }
}

export default Period;