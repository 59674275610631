import React, {useEffect, useContext, useState, useCallback} from 'react'
import { useSelector } from 'react-redux'

import SearchResult from './search_result.js'
import { searchListings, commonHeaders } from '../../helpers/api_routes.js'
import {FiltersContext} from '../../helpers/filters_context.js'
import Loader from '../loader.js'

import InfiniteScroll from 'react-infinite-scroller';

import { debounce } from 'lodash';


//import Loader from '../loader.js'

const axios = require('axios');

function SearchResults(props) {
  
  //filters 
  const filters = useContext(FiltersContext)
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [items] = useState(10)
  const [offset] = useState(0)
  const [noResults, setNoResults] = useState(false)

  // data from redux
  const user = useSelector(state => state.usersStatus)
  const params = filters.params

  //combine common headers with users session id
  const headers = {...commonHeaders , ...{"Adm-Session-ID": user.sessionId} }

  useEffect(() => {
    setResults([])
  }, [])

  const fetchData = (searchListings, params, items, offset, headers) => {
		axios.get(`${searchListings}${params}${'&limit=' + items}${'&offset=' + offset}`, {headers: headers}).then(res => {
			if (res.data) {
				setResults(res.data)
        setIsLoading(false)
        setNoResults(false)
			} else {
        setNoResults(true)
      }
		}).catch(err => {
      setError(err.message)
      console.log(error)
		})
  }

  const fetchMoreData = () => {
    if (user && results.length < filters.advice.count) {
      axios.get(`${searchListings}${params}${'&limit=' + items}${'&offset=' + results.length}`, {headers: headers}).then(res => {
        if (res.data) {
          setResults(results.concat(res.data))
          setIsLoading(false)
        }
      }).catch(err => {
        setError(err.message)
        console.log(error)
      })
    }
	}

  const handleDataThrottle = useCallback(debounce(fetchData, 200), []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let isSubscribed = true 
    if (user) {
      handleDataThrottle(searchListings, params, items, offset, headers)
    }
    return () => isSubscribed = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.searchState]);

  if (isLoading) {
    return <Loader />
  } else {
    if (noResults) {
      return <div className="search-empty" data-aos="fade-in">no results found</div>
    } else { 

        return (
          <div className="layout--main">
            {
              results.length > 0 &&
            
            <section className="search-results" key={32432}>
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchMoreData}
                hasMore={true || false}
              >
                { results.map((result) => 
                  <div  key={result.advertisement_id} data-aos="fade-in"> 
                    <SearchResult listing = {result} id = {result.advertisement_id} user = {user} isLoading={isLoading} />
                  </div>
                ) }
              </InfiniteScroll>
            </section>
            }
          </div>
        )   
    }
  }

}


export default SearchResults;
