//libraries
import React, {useState, useContext} from 'react';
import NeighborhoodInput from './neighborhood_input.js'
import {FiltersContext} from '../../helpers/filters_context.js'

const FilterNeigbhorhood = (props) => {

	const filters = useContext(FiltersContext)
	const [selected] = useState([]);

	// TODO: make this dynamic
	const boroughs = filters.options.boroughs

	const handleCheck = (e) => {
		filters.setBoroughs(e.target.id);
	}

	if (boroughs) {
		return (

			<React.Fragment>
				<div className="subheading--big">Neighborhood</div>
				 <section className="subsection">

				 		{Object.keys(boroughs).map((obj, i) =>  
				 			boroughs[obj].neighborhoods.length > 0 &&
							<NeighborhoodInput id={boroughs[obj].name} value={boroughs[obj].name} key={i} neighborhoods={boroughs[obj].neighborhoods} handleCheck={handleCheck} selected={selected}/>
						)}


				</section> 

			</React.Fragment>
		)
	} else {
		return null
	}

	

}

export default FilterNeigbhorhood;


 