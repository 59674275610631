import React, {Component} from 'react';
import dummy from '../images/dummy/bitmap.jpg';
//import '../App.css';

class SearchResultShow extends Component {
  constructor(props) {
    super(props);
    this.state = { }
  }

  render() {

    var tab = document.querySelector('.js_tab');
    console.log(tab);

    return (
      <div className="search-result-show">
        <header className="single-result-header">
          <div className="single-result-header__img">
            <img src={dummy} alt="dummy" />
          </div>
          <div className="single-result-header__tags"></div>
          <div className="single-result-header__btns"></div>
        </header>

        <div className="layout--main">
          <section className="section section--border">
            <div className="item-list margin-bottom">
              <span>1 bed</span>
              <span>2 bath</span>
              <span>370ft</span>
            </div>
            <div className="text--xsmall">Address goes here</div>
          </section>

          <section className="section section--border">
            <div className="grid">
              
              <div className="cell cell--2--small">
                <div className="subsection">
                  <div className="subheading">condo</div>
                  <div className="bold text--xlarge">$ 4,215 </div>
                </div> 
              </div>
              <div className="cell cell--2--small">
                <div className="subsection subsection--right">
                  <div className="subheading">period</div>
                  <div className="semibold text--primary text--medium"> period goes here </div>
                </div> 
              </div>
            </div>

          </section>

          <section className="section section--border">
            
            <div className="grid">
              
              <div className="cell cell--2--small">
                <div className="subsection">
                  <div className="subheading">days on market</div>
                  <div className="semibold text--medium">$ 15 </div>
                </div> 
              </div>
              <div className="cell cell--2--small">
                <div className="subsection subsection--right">
                  <div className="subheading">available</div>
                  <div className="semibold text--primary text--medium"> 4/15 </div>
                </div> 
              </div>
            </div>

          </section>

          <section className="section section--border">
            
            <div className="grid">          
              <div className="cell cell--4--small">
                <div className="subsection">
                  <div className="subheading">incentive</div>
                  <div className="semibold text--medium text--green margin-bottom--double"> 1 month something  </div>
                  <ul>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                  </ul>
                </div> 
              </div>
            </div>

          </section>

          <section className="section section--border">
            
            <div className="grid">          
              <div className="cell cell--4--small">
                <div className="subsection">
                  <div className="subheading">about</div>
                  <p className="no-margin">Well this is some text <br />
                  it has line breaks
                  </p>
                </div> 
              </div>
            </div>

          </section>

          <section className="section section--border">
            
            <div className="grid">          
              <div className="cell cell--4--small">
                <div className="subsection">
                  <div className="subheading">building amenities</div>
                  <ul>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                  </ul>
                </div> 
              </div>
            </div>

          </section>

          <section className="section section--border">
            
            <div className="grid">          
              <div className="cell cell--4--small">
                <div className="subsection">
                  <div className="subheading">apartment amenities</div>
                  <ul>
                    <li>list item</li>
                    <li>list item</li>
                    <li>list item</li>
                  </ul>
                </div> 
              </div>
            </div>

          </section>

          <section className="tabs">
            <div className="tabs__header">
              <div className="tab js_tab">rentals</div>
              <div className="tab js_tab">sales</div>
            </div>
            <div className="tabs__body">
              <div className="tab__content js_tab_content">
                <div className="related-listing">
                  <div className="related-listing__header">
                    <div className="item-list item-list--small">
                      <span>1 bed</span>
                      <span>2 bath</span>
                      <span>370ft</span>
                    </div>
                    <div className="bold">$4020</div>
                  </div>
                  <div className="related-listing__body">
                    <div className="text--gray">Owner Pays, Milford Management</div>
                    <div>DOM 28</div>
                  </div>
                  <div className="related-listing__btns">
                    <div className="btn--square">photos</div>
                    <div className="btn--square">floorplan</div>
                  </div>
                </div>
              </div>
              <div className="tab__content js_tab_content">tab 2 content</div>
            </div>
          </section>

        </div>

      </div>
    );
  }
}

export default SearchResultShow;
