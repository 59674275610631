//libraries
import React from 'react';
import NumberFormat from 'react-number-format';

function Price (props) {
    
    return ( 

		<div className="bold">
            <NumberFormat value={props.listing.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </div>

    );

}

export default Price;
