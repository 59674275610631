	//libraries
import React, {useContext} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'

const Neighborhood = (props) => {  

	const filters = useContext(FiltersContext)
	const id = props.id

	const handleCheck = (e) => {
		if (filters.areas.indexOf(id) < 0) {
			filters.setAreas(filters.areas.concat([id]))	
		} else {
			filters.setAreas(filters.areas.filter(val => val !== id ))
		}
	}

	return (
		<React.Fragment>
			<div className="input--checkbox__wrap input--checkbox__wrap--small">
	        	<input type="checkbox" id={'area_' + props.id} value={props.value} className="input--checkbox" onChange={handleCheck} checked={filters.areas.indexOf(props.id) !== -1}/>
				<label htmlFor={'area_' + props.id}>{props.value}</label>
	    	</div>
    	</React.Fragment>
	)

}

export default Neighborhood;


 