//libraries
import React, {useContext} from 'react';
import Spinner from './rooms_spinner.js'
import {FiltersContext} from '../../helpers/filters_context.js'

const FilterRoomType = (props) => {

	const filters = useContext(FiltersContext)

	// get bedrooms from search options 
	var bedrooms = filters.options.bedrooms

	if (bedrooms) {
		return (
			<Spinner label="Number of rooms" labelClass="input-label--small" bedrooms={bedrooms} />
		)
	} else {
		return null
	}
	
}

export default FilterRoomType;


 