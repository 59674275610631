import React from 'react';

const ItemList = (props) => {

    const listing = props.listing
    
    return (
     <div className="item-list margin-bottom">
        { listing.bedrooms && <span>{listing.bedrooms} bed</span> }
        { listing.bathrooms !== 0 && <span>{ listing.bathrooms + ' bath'}</span>} 
        {listing.size !== 0 && <span>{listing.size + ' sqft'}</span>} 
      </div>
    )
  }


export default ItemList;