import React, {Component} from 'react';
import logo from '../images/logo.svg';
//import '../App.css';

class PageTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movies: [],
      isLoaded: false
    }

    this.makeList = this.makeList.bind(this);
  }

  componentDidMount() {
    fetch('http://www.omdbapi.com/?s=star&apikey=480344f1&r=json' ) 
    .then(res => res.json())
    .then(json => {
      this.setState({
        movies: json.Search,
        isLoaded: true
      })
    });
  }

  makeList() {
    return (this.state.movies.map( movie => {
      return(<li key={movie.imdbID}> {movie.Title}  </li>)
    }))
  }

  render() {
    if (this.state.isLoaded) {
    return (
      <div>
        <img src={logo} className="App-logo" alt="logo" />
        <h1> Loaded page Two </h1>
        <ul> 
          {this.makeList()}
        </ul>
      </div>
    );
    } else {
      return(
        <div>
          <h1> Loading... </h1>
        </div>
      )
    }
  }
}

export default PageTwo;
