import React from 'react';

 const BuildingAmenities = (props) => {

		const listing = props.listing;

		if (listing.building_amenities) {
			return (
				
					<ul>
					  { listing.building_amenities.map((item,i) => <li key={i}>{item}</li>) }
					</ul>
			
			)
		} else {
			return <div>No building amenities</div>
		}

		
	}


export default BuildingAmenities;