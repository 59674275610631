import React, {PureComponent} from 'react'
import {mediaUrl} from '../../helpers/api_routes.js';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default class SearchResultShowSlider extends PureComponent {

    constructor() {
        super();
        this.changeHandler = this
            .handleChangeNext
            .bind(this)
        this.state = {
            isOpen: false,
            photoIndex: 0,
            allowGallery: true
        }
    }

    handleChangeNext(images, photoIndex) {
        this.setState({
            photoIndex: photoIndex
        })
        this
            .sliderWrapper
            .innerSlider
            .slickGoTo(this.state.photoIndex + 1)
    }

    handleChangePrev(images, photoIndex) {
        this.setState({
            photoIndex: photoIndex
        })
        this
            .sliderWrapper
            .innerSlider
            .slickGoTo(this.state.photoIndex - 1)
    }

    render() {

        let galleryView = this.props.galleryView

        const images = this
            .props
            .photos
            .map(item => {
                return `${mediaUrl}/${item.path
                    ? item.path
                    : item}`
            })

        const {photoIndex, isOpen} = this.state;

        var settings = {
            dots: true,
            infinite: true,
            speed: 220,
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: current => this.setState({photoIndex: current})
        };

        return (
            <div className="slider">
                {this.props.photos.length > 1
                    ? <Slider {...settings} ref={sliderWrapper => this.sliderWrapper = sliderWrapper}>
                            {this.props.photos && this
                                .props
                                .photos
                                .map((obj, i) => <img
                                    src={`${mediaUrl}/${obj.path
                                    ? obj.path
                                    : obj}`}
                                    key={i}
                                    alt='Slider item'
                                    onClick={() => {
                                    this.setState({isOpen: true})
                                }}/>)
}
                        </Slider>
                    : <img
                        src={`${mediaUrl}/${this.props.featured_photo ? this.props.featured_photo: this.props.photos[0].path}`}
                        alt='Featured'/>
}

                {isOpen && galleryView && this.state.allowGallery && (<Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({isOpen: false})}
                    imagePadding={0}
                    onMovePrevRequest={() => this.handleChangePrev(images, photoIndex)}
                    onMoveNextRequest={() => this.handleChangeNext(images, photoIndex)}/>)}

            </div>
        )
    }
}
