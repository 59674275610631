//libraries
import React, {useContext, useState, useEffect} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'
import spinnerInc from '../../images/icons/spinner-inc.svg'
import spinnerDec from '../../images/icons/spinner-dec.svg'

const Spinner = () => {  

	const filters = useContext(FiltersContext)
	const [counter, setCounter] = useState({count: 0})
	const [isMin, setIsMin] = useState(false)
	const [isMax, setIsMax] = useState(false)

	let bedrooms = filters.options.bedrooms
	let minBedrooms = filters.bedrooms

	const setInitialState = () => {
			var i = bedrooms.indexOf(minBedrooms)
			if (i < 0) {
				setCounter({count: 0})
			} else {
				setCounter({count: i})
			}
	}

	useEffect(() => {
		setInitialState()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// handle reset of bedrooms filter 
	useEffect(() => {
		if (minBedrooms.length === 0) {
			setCounter({count: 0})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	const decrement = (e) => {
  		setCounter(prevState => {
  		if (!counter.count < 1) {
  			return {count:  prevState.count - 1}
  		} else {
  			return {count:  prevState.count}
  		}
      })
  	}

  	const increment = (e) => {
  		setCounter(prevState => {
        if (counter.count !== bedrooms.length - 1) {
  			return {count:  prevState.count + 1}
	  	} else {
			return {count:  prevState.count}
		}
      })
  	}

  	useEffect(() => {
  		filters.setBedrooms(bedrooms[counter.count])
  	// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, [counter])

  	useEffect(() => {
  		setIsMin(counter.count < 1 ? true : false)
  		setIsMax(counter.count === bedrooms.length - 1 ? true : false)
  	// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, [counter])

  	let spinner_dec = isMin ? 'spinner__btn spinner__btn--inactive' : 'spinner__btn'
  	let spinner_inc = isMax ? 'spinner__btn spinner__btn--inactive' : 'spinner__btn'

	return (
		<div className="spinner__wrap">
			<label className="input-label--big">
				Bedrooms
			</label>
			<div className="spinner spinner--fixed spinner--fixed--big">
				<div className={spinner_dec} onClick={decrement}><img src={spinnerDec} alt="decrement" /></div>
					<span>{filters.bedrooms}</span>
				<div className={spinner_inc} onClick={increment}><img src={spinnerInc} alt="increment" /></div>
	    	</div>
	    </div>
	)
}
export default Spinner;


 