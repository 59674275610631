//libraries
import React from 'react';
import { Link } from 'react-router-dom';
import SignOut from '../components/session/sign_out.js';

//import iconAgent from '../images/icons/menu/icon-agents.svg';
// import iconArrowDown from '../images/icons/icon-arrow-down.svg';

// styles
//import '../App.css';

const Dashboard = (props) => {

    return (
      <section className="dashboard" data-aos="fade-right">
        <div className="layout--main">

          <div className="dashboard__header" data-aos="fade-in">
          <Link to="/">
            Dashboard
          </Link>
          </div>
          <div className="dashboard__section" data-aos="fade-in">
            <SignOut isSuccess = {props.isSuccess} />
          </div>
        </div>
      </section>     
    );
}

export default Dashboard;
