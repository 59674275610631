//libraries
import React from 'react';
import NumberFormat from 'react-number-format';


function PriceChange (props) {

	const price_change = props.listing.price_change
	
	var priceChangeClass
	var prefix

	if (price_change > 0) {
		prefix = '+ $ '
		priceChangeClass = props.simple ? "price-change--simple price-change--positive" : "semibold text--medium price-change price-change--positive"
	}
	if (price_change < 0) {
		prefix = '- $ '
		priceChangeClass = props.simple ? "price-change--simple price-change--negative" : "semibold text--medium price-change price-change--negative"
	}
	if (price_change === 0) {
		prefix = ''
		priceChangeClass = props.simple ? "" : "semibold text--medium price-change"
	}

    
	if (price_change !== null) {
		if (props.simple === true) {
			return <div className={priceChangeClass}></div>
		} else {
			if (price_change !== 0) {
				return (
					<div className={priceChangeClass}><NumberFormat value={price_change} displayType={'text'} thousandSeparator={true} prefix={prefix} /></div>
			  	)
			} else {
				return <div className="semibold text--medium">-</div>
			}
		}
		
	} else {
		return <div>-</div>
	}
}

export default PriceChange;
