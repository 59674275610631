const usersStatusReducer = (state = 0, action ) => {
  switch(action.type) {
    case "addUser":
      return state = action.payload;
    case "removeUser":
      return state = action.payload;
    default: 
      return state;
  }
}

export default usersStatusReducer;
