//libraries
import React, {useContext} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'

const BuildingTypeInput = (props) => {  

	const filters = useContext(FiltersContext)

	const handleCheck = (e) => {
		if (filters.buildingTypes.indexOf(e.target.value) < 0) {
			filters.setBuildingTypes(filters.buildingTypes.concat([e.target.value]))	
		} else {
			filters.setBuildingTypes(filters.buildingTypes.filter(val => val !== e.target.value ))
		}
	}

	return (
		<div className="input--checkbox__wrap">
	        <input type="checkbox" id={props.id} value={props.value} className="input--checkbox" onChange={handleCheck} checked={filters.buildingTypes.indexOf(props.value) !== -1}/>
	        <label htmlFor={props.id}>{props.value}</label>
	    </div>
	)

}

export default BuildingTypeInput;


 