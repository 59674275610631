//libraries
import React from 'react';

function VisitingTime (props) {

    
    if (props.listing.open_house_info) {
    	return ( 

			<div className="text--small">{props.listing.open_house_info}</div>

	    );
    } else {
    	return <div className="text--small">N/A</div>
    }
    

}

export default VisitingTime;
