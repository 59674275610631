import { getCurrentUser } from '../helpers/current_user'
export const addUserAction = function(currentUser = getCurrentUser() ) {
  return {
    type: "addUser",
    payload: currentUser
  };
};

export const removeUserAction = function() {
  return {
    type: "removeUser",
    payload: false
  };
};
