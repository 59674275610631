import React from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { addUserAction } from '../../actions/users_status.js'

// helpers
import { googleClientId } from '../../helpers/variables.js';
import { authUrl, authHeaders } from '../../helpers/api_routes.js'
import { addCurrentUser } from '../../helpers/current_user.js';

const axios = require('axios');

function SignIn(props) {
  // redux connection
  //const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();

  if (props) {
    return (
    <div>
    
    <GoogleLogin
    clientId={googleClientId}
    buttonText="Log in with Google"
    responseType="code"
    accessType="offline"
    onSuccess={(response) => {
      axios.post(authUrl, {
        "code": `${response.code}`
      }, {headers: authHeaders }).then(json => {
        // save user and session to localstorage + save currentUser to redux (from local storage)
        dispatch(addUserAction( addCurrentUser(json) ));

        // check in App.js if conditions are Ok for redirect
        props.isSuccess();
      });
    }}
    onFailure={(response) => console.log(response)}
    cookiePolicy={'single_host_origin'} />

    </div>
  )
  } else {
    return <div>error</div>
  }
  
}

export default SignIn;
