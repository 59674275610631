import React from 'react';

 const Available = (props) => {

		const listing = props.listing;

		if (listing.available_from) {
			return (
				<div className="semibold text--primary text--medium">{listing.available_from}</div>
			)
		} else {
			return null
		}

		
	}


export default Available;