import React from 'react';

const CompanyInfo = (props) => {

	if (props.listing.leasing_contact) {
		let leasing_contact = Object.values(props.listing.leasing_contact).map(obj => obj)
 		let name = leasing_contact[0]
 		let address = leasing_contact[1]
 		let phone = leasing_contact[2]
 		let email = leasing_contact[3]
 		let fax = leasing_contact[4]
 		let website = leasing_contact[5]

 		let mailto = `mailto:${email}`

 		return (
			<React.Fragment>
				<div className="semibold">{name}</div>
				<div>A: {address}</div>
				<div>P: {phone}</div>
				<div>E: <a href={mailto} className="text--primary">{email}</a></div>
				<div>F: {fax}</div>
				<div>W: <a href={website} className="text--primary">{website}</a></div>
			</React.Fragment>
		)

	} else {
		return <div>N/A</div>
	}
}


export default CompanyInfo;