import React, {useState, createContext, useEffect, useCallback} from 'react';
import { searchOptions, listingsAdvice, commonHeaders } from './api_routes.js'
import { debounce } from 'lodash';


const axios = require('axios');

export const FiltersContext = createContext();

export const FiltersProvider = (props) => {
	
	const user = props.user
	const headers = {...commonHeaders , ...{"Adm-Session-ID": user.sessionId} }

	// detailed results on or off  
	const [detailedResults, setDetailedResults] = useState([])

	// filters 
	const [buildingTypes, setBuildingTypes] 	= useState([])
	const [bedrooms, setBedrooms] 				= useState([])
	const [pets, setPets] 						= useState([])
	const [minPrice, setMinPrice] 				= useState([])
	const [maxPrice, setMaxPrice] 				= useState([])
	const [boroughs, setBoroughs] 				= useState([])
	const [areas, setAreas] 					= useState([])
	const [minBathrooms, setMinBathrooms] 		= useState([])
	const [resultList, setResultList] 			= useState([])
	const [randomNumber, setRandomNumber] 		= useState([])
	const [phrase, setPhrase] 					= useState('')
	const [adTypes, setAdTypes]					= useState([])
	// eslint-disable-next-line no-unused-vars
	const [isLoaded, setIsLoaded] 				= useState(false)
	const [params, setParams] 					= useState('?ad_type=rental')

	const [options, setOptions] = useState([])
	const [advice, setAdvice] = useState([])
	const [error, setError] = useState('')

	const [searchState, setSearchState] = useState(false)  

	const fetchData = () => {
		
		setIsLoaded(true)

		if (user) {
			axios.get(`${searchOptions}`, {
			  headers: headers 
			}).then(res => {
				if (res.data.length !== 0) {
					setOptions({
						boroughs: res.data.boroughs,
						building_type: res.data.building_type,
						bedrooms: res.data.bedrooms,
						bathrooms: res.data.bathrooms,
						pets: res.data.pets
					})
				}
			}).catch(err => {
			  setError(err.message);
			  console.log(error)
			})			
		
		}		
	}

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let isSubscribed = true
			fetchData()
		return () => isSubscribed = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	useEffect(() => {

		if (detailedResults.length < 1) {
			setDetailedResults('simple')
		}
		if (adTypes.length < 1)  {
			setAdTypes('rental')
		}
		if (boroughs.length < 1) {
			setBoroughs('Manhattan')
		}

		var formatPhrase = phrase.toString().replace(/,/g , "").replace(/ /g,"%20")
		var params_neighborhood = areas.map(obj => obj)
		var params_building_type = buildingTypes && buildingTypes.map(obj => `building_type=${obj}&`)
		var params_bedrooms = 'bedrooms=' + bedrooms.toString().replace(/ /g,"%20").replace(/\+/g, "%2B");
		var params_bathrooms = 'bathrooms=' + minBathrooms
		var params_ad_type = adTypes.toString().replace(/ /g,"%20")
		var params_pets = 'pets=' + pets.toString().replace(/ /g,"%20")
		var params_minPrice = `min_price=${minPrice.toString().replace(/NaN/g,"")}`
		var params_maxPrice = `max_price=${maxPrice.toString().replace(/NaN/g,"")}`
		
		params_neighborhood =  params_neighborhood.toString().replace(/ /g,"%20")
		params_building_type = params_building_type.toString().replace(/,/g , "").replace(/ /g,"%20")
		params_bedrooms = params_bedrooms.toString().replace(/,/g , "").replace(/ /g,"%20")

		if (adTypes.length > 0) {
			setParams( 
			`?ad_type=${params_ad_type}&` + 
			`search=${formatPhrase}&` + 
			`${params_building_type}` + 
			`${params_bedrooms}&` + 
			`neighborhood_id=${params_neighborhood}&` + 
			`${params_bathrooms}&` + 
			`${params_pets}&` +
			`sort_by=time_published&` + 
			`sort_dir=desc&` + 
			`${params_maxPrice}&` + 
			`${params_minPrice}&` 
			)
		}

	}, [minPrice, maxPrice, phrase, areas, buildingTypes, bedrooms, adTypes, minBathrooms, pets, boroughs.length, detailedResults.length])

	const fetchLocalFilters = () => {

		let localAdTypes = localStorage.getItem('adTypes')
		let localMinPrice = localStorage.getItem('min_price')
		let localMaxPrice = localStorage.getItem('max_price')
		let localBuildingTypes = JSON.parse(localStorage.getItem('buildingTypes'))
		let localBoroughs = localStorage.getItem('boroughs')
		let localAreas = JSON.parse(localStorage.getItem('areas'))
		let localBedrooms = localStorage.getItem('bedrooms')
		let localMinBathrooms = localStorage.getItem('minBathrooms')
		let localPhrase = localStorage.getItem('phrase')
		let localDetailedResults = localStorage.getItem('detailedResults')
		let localPets = localStorage.getItem('pets')

		if (localAdTypes) {
			setAdTypes(localAdTypes)
		}
		if (localMinPrice) {
			setMinPrice(localMinPrice)
		}
		if (localMaxPrice) {
			setMaxPrice(localMaxPrice)
		}
		if (localBuildingTypes) {
			setBuildingTypes(localBuildingTypes)
		}
		if (localBoroughs) {
			setBoroughs(localBoroughs)
		}
		if (localAreas) {
			setAreas(localAreas)
		}
		if (localBedrooms) {
			setBedrooms(localBedrooms)
		}
		if (localMinBathrooms) {
			setMinBathrooms(localMinBathrooms)
		}
		if (localPhrase) {
			//setPhrase(localPhrase)
		}
		if (localDetailedResults) {
			setDetailedResults(localDetailedResults)
		}
		if (localPets) {
			setPets(localPets)
		}
	}

	useEffect(() => {
		fetchLocalFilters()
	}, [props])

	const fetchAdvice = (params, headers) => {
		axios.get(`${listingsAdvice}${params}`, {headers: headers}).then(res => {
			if (res.data) {
				setAdvice(res.data)
				setSearchState(res.data.title)
			}
		}).catch(err => {
		  setError(err.message)
		  console.log(error)
		})
	}

	const handleAdviceThrottle = useCallback(debounce(fetchAdvice, 400), []);

	useEffect(() => {
			// eslint-disable-next-line no-unused-vars
			let isSubscribed = true
  			if (user) {
	    		handleAdviceThrottle(params, headers);
	    	}
	    	return () => isSubscribed = false
  	// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, [params]);

  	const setLocalFilters = () => {
  		localStorage.setItem('min_price', minPrice)
		localStorage.setItem('max_price', maxPrice)
		localStorage.setItem('buildingTypes', JSON.stringify(buildingTypes))
		localStorage.setItem('detailedResults', detailedResults)
		localStorage.setItem('boroughs', boroughs)
		localStorage.setItem('areas', JSON.stringify(areas))
		localStorage.setItem('bedrooms', bedrooms)
		localStorage.setItem('minBathrooms', minBathrooms)
		localStorage.setItem('phrase', phrase)
		localStorage.setItem('adTypes', adTypes)
		localStorage.setItem('pets', pets)
  	}

	useEffect(() => {
		setLocalFilters()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minPrice, maxPrice, buildingTypes, detailedResults, boroughs, areas, bedrooms, minBathrooms, phrase, adTypes, pets])

	const resetFilters = () => {
		setBuildingTypes([])
		setBedrooms([])
		setPets([])
		setMinPrice('')
		setMaxPrice('')
		setBoroughs([])
		setAreas([])
		setMinBathrooms([])
		setBedrooms([])
		setPhrase('')
		setAdTypes('rental')
	}
	
	return(
		<FiltersContext.Provider value={{
			advice,
			buildingTypes, setBuildingTypes,
			bedrooms, setBedrooms,
			minBathrooms, setMinBathrooms,
			pets, setPets,
			minPrice, setMinPrice,
			maxPrice, setMaxPrice,
			boroughs, setBoroughs,
			areas, setAreas,
			phrase, setPhrase,
			resultList, setResultList,
			resetFilters: resetFilters,
			randomNumber, setRandomNumber,
			detailedResults, setDetailedResults,
			options,
			params,
			adTypes, setAdTypes,
			searchState, setSearchState
		}}>
			{props.children}
		</FiltersContext.Provider>
	);
}