//libraries
import React, {useContext} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'
import Spinner from './bathrooms_spinner.js'

const FilterBathrooms = (props) => {  
	const filters = useContext(FiltersContext)
	
	if (filters.options.bathrooms) {
		return (
			<React.Fragment>
						<Spinner label="Bathrooms" labelClass="input-label--big" />
	    	</React.Fragment>
		)
	} else {
		return null
	}
	
}

export default FilterBathrooms;


 