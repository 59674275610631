//libraries
import React, {useContext} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'


const ViewTypeToggle = (props) => {  

	const filters = useContext(FiltersContext);

	const handleSelectedOption = (e) => {
	    props.setSelectedOption(e.target.value)
	    filters.setDetailedResults(e.target.value)
	}

	return (
		<React.Fragment>
			<div className="input--radio__wrap">
	        	<input type="radio" id={props.value} value={props.value} name={props.name} className="input--radio" onChange={handleSelectedOption} checked={ filters.detailedResults === props.value } />
	        	<label htmlFor={props.value}>{props.name}</label>
	        </div>
    	</React.Fragment>
	)

}

export default ViewTypeToggle;


 