//libraries
import React from 'react';

function Tags (props) {
    
    

      if (props.listing) {
        return ( 
           <div className="tags">
              {props.listing.has_360 !== 0 &&
                <span className="tags__tag tags__tag--blue">360 view</span>
              }
              {props.listing.has_floorplan !== 0 &&
                <span className="tags__tag tags__tag--turquoise">floorplan</span>
              }
              {props.listing.nofee !== 0 &&
                <span className="tags__tag tags__tag--green">no fee</span>
              }

          </div>
        )
      } else {
        return null
      }
}

export default Tags;
