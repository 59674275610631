//libraries
import React from 'react';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns'



function PriceChange (props) {

	const price_change = props.listing.price_change
	
	var priceChangeClass
    var prefix

    // handle date 
    var result = new Date(props.listing.price_history[0].time*1000);
    var lastDate = format(new Date(result), 'MM/dd/yyyy')

	if (price_change > 0) {
		prefix = '+ $ '
		priceChangeClass = props.simple ? "price-change--simple price-change--positive" : "semibold text--medium price-change price-change--positive"
	}
	if (price_change < 0) {
		prefix = '- $ '
		priceChangeClass = props.simple ? "price-change--simple price-change--negative" : "semibold text--medium price-change price-change--negative"
	}
	if (price_change === 0) {
		prefix = ''
		priceChangeClass = props.simple ? "" : "semibold text--medium price-change"
	}

    
	if (price_change !== null) {
		if (props.simple === true) {
			return <div className={priceChangeClass}></div>
		} else {
			if (price_change !== 0) {
				return (
                    <div className="price-change-row">
                        <div className="text--small semibold text--gray">{lastDate}</div>
    					<div className={priceChangeClass}><NumberFormat value={price_change} displayType={'text'} thousandSeparator={true} prefix={prefix} /></div>
                    </div>
			  	)
			} else {
				return <div className="semibold text--medium">-</div>
			}
		}
		
	} else {
		return <div>-</div>
	}
}

export default PriceChange;
