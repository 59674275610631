//libraries
import React from 'react';

function Address (props) {

    
    return ( 

		<div className="text--small">{props.listing.address} {props.listing.unit && ', ' + props.listing.unit}</div>

    );

}

export default Address;
