//libraries
import React from 'react';

function Loader (props) {

    return ( 

		<div className="loader" data-aos="fade-in">
			<div></div>
			<div></div>
			<div></div>
		</div>

    );

}

export default Loader;
