import React, {Component} from 'react';
import logo from '../images/logo.svg';
//import '../App.css';

class PageThree extends Component {
  constructor(props) {
    super(props);
    this.state = { }
  }

  render () {
    return (
      <div>
          <img src={logo} className="App-logo" alt="logo" />
          <h1> Page Three </h1>
      </div>
    );
  }
}

export default PageThree;
