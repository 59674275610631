const searchResultsReducer = (state = [], action ) => {
  switch(action.type) {
    case "addResults":
      return state = action.payload;
    default: 
      return state;
  }
}

export default searchResultsReducer;
