//libraries
import React from 'react';

function ImgPlaceholder (props) {

    return ( 

		<div className="img-placeholder">No photo</div>

    );

}

export default ImgPlaceholder;
