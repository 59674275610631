import React, {useState, createContext} from 'react';

export const ViewContext = createContext();

export const ViewProvider = (props) => {
	
	const [nav, setNav] = useState(true);

	return(
		<ViewContext.Provider value={{nav, setNav}}>
			{props.children}
		</ViewContext.Provider>
	);
}