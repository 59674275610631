import React from 'react';

const Address = (props) => {

    const listing = props.listing
    
    return (
     <div className="text--xsmall">{listing.address}{listing.unit && ', ' + listing.unit}</div>
    )
  }


export default Address;