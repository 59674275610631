//libraries
import React from 'react';

function ProviderInfo (props) {
	var access_type_info = props.listing.access_type_info.split(',')

    if (props.listing.access_type_info) {
    	return ( 
    		<React.Fragment>
			<div className="text--small">
				{access_type_info.map((obj, i) => <div key={i}>{obj}</div>)}
			</div> 
			</React.Fragment>
	    );
    } else {
    	return null
    }
}

export default ProviderInfo;
