//libraries
import React from 'react';

function Available (props) {

	const available_from = props.listing.available_from
    
    if (available_from) {
    	return <div className="semibold text--medium text--primary">{available_from}</div>
    } else {
    	return <div>Undefined</div>
    }
    

}

export default Available;
