//libraries
import React from 'react';

// components 
import Address from './search_result_address.js';
import Tags from './search_result_tags.js';
import Image from './search_result_img.js';
import Slider from '../../search-result-show/search_result_show_slider';
import Info from './search_result_info.js';
import Price from './search_result_price.js';
import Days from './search_result_days.js';
import ProviderInfo from './search_result_provider_info.js';
import Features from './search_result_features.js';
import Available from './search_result_available.js';
import PriceChange from './search_result_price_change.js';
import VisitingTIme from './search_result_visiting_time.js';


function SearchResultDetailed (props) {

    // get current date and listing date
    //const time = props.listing.data.timeCreated*1000;


    // see if the date created is same as current date

    const photosCount = props.listing.photos_count;
    
    return ( 
         <div className="search-result">
            <div className="search-result__img search-result__img--big">
              <Tags listing={props.listing} />
              {photosCount > 1 ? <Slider photos={props.listing.photos} /> : <Image listing={props.listing} /> }
            </div>

            <div className="search-result__body">

              <div className="info-row section--border">
                <div className="subsection">
                  <div className="margin-bottom">
                    <Info listing={props.listing} />
                  </div>
                  <Address listing={props.listing} />
                </div>
              </div>

              <section className="section section--border">
                <div className="grid">
                  <div className="cell cell--2--small">
                    <div className="subsection">
                        <div className="subheading">{props.listing.ad_type}</div>
                        <div className="text--xlarge">
                          <Price listing={props.listing} />
                        </div>
                    </div>
                  </div>
                  <div className="cell cell--2--small">
                    <div className="subsection subsection--right">
                        <div className="subheading">price change</div>
                        <PriceChange listing = {props.listing} />
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section--border">
                <div className="grid">
                  <div className="cell cell--2--small">
                    <div className="subsection">
                        <div className="subheading">DAYS ON MARKET</div>
                        <Days listing={props.listing} />
                    </div>
                  </div>
                  <div className="cell cell--2--small">
                    <div className="subsection subsection--right">
                        <div className="subheading">AVAILABLE</div>
                        <Available listing = {props.listing} />
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section--border">
                <div className="grid">
                  <div className="cell cell--2--small">
                    <div className="subsection">
                        <div className="subheading">PROVIDER INFO</div>
                        <div className="text--small no-margin">
                          <ProviderInfo listing={props.listing} />
                        </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section--border">
                <div className="grid">
                  <div className="cell cell--4--small">
                    <div className="subsection">
                        <div className="subheading">Features</div>
                        <Features listing={props.listing} />
                    </div>
                  </div>
                </div>
              </section>

               <section className="section section--border">
                <div className="grid">
                  <div className="cell cell--4--small">
                    <div className="subsection">
                        <div className="subheading">Visiting time</div>
                        <VisitingTIme listing={props.listing} />
                    </div>
                  </div>
                </div>
              </section>

            </div>
          </div>

    );

}

export default SearchResultDetailed;
