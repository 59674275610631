import React from 'react';

const AccessInfo = (props) => {

		if (props.listing.access_info) {
			return (
				<div>{props.listing.access_info}</div>
			)
		} else {
			return <div>N/A</div>
		}

		
	}


export default AccessInfo;