//libraries
import React, {useContext} from 'react';
import Neighborhood from './neighborhood_single.js'
import {FiltersContext} from '../../helpers/filters_context.js'


const NeighborhoodInput = (props) => {  

	const filters = useContext(FiltersContext)
	const showHideClassName = filters.boroughs === props.id ? "input__dropdown input__dropdown--active" : "input__dropdown input__dropdown--hidden";

	return (
		<React.Fragment>
			<div className="input--radio__wrap">
	        	<input type="radio" id={props.id} value={props.value} className="input--radio" onChange={props.handleCheck} checked={filters.boroughs === props.value} />
	        	<label htmlFor={props.id}>{props.value}</label>
	        </div>
	        { props.neighborhoods.length > 0 &&
	        	<div className={showHideClassName}>
		    		{
		    			Object.keys(props.neighborhoods).map((obj, i) => 
		    				<Neighborhood id={props.neighborhoods[obj].neighborhood_id} value={props.neighborhoods[obj].name} key={props.neighborhoods[obj].neighborhood_id} selected={props.selected} />
		    			)
		    		}
		    	</div>
	        }
	        
    	</React.Fragment>
	)

}

export default NeighborhoodInput;


 