import React from 'react';

const Days = (props) => {

	if (props.listing.dom) {
 		return (
			<div className="semibold">{props.listing.dom}</div>
		)
	} else {
		return <div className="semibold">0</div>
	}
}


export default Days;