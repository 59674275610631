//libraries
import React, {useContext} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'


const AdType = (props) => {  

	const filters = useContext(FiltersContext)

	let adTypes = [
		{
			label: 'Rental',
			value: 'rental'
		},
		{
			label: 'Sale',
			value: 'sale'
		}
	]

	const handleCheck = (e) => {
		filters.setAdTypes(e.target.value)
	}

	return (
		<React.Fragment>
			{
				adTypes.map((obj,i) => 
					<div className="input--radio__wrap" key={i}>
			        	<input type="radio" id={obj.value} value={obj.value} name={obj.label} className="input--radio" onChange={handleCheck} checked={filters.adTypes === obj.value} />
			        	<label htmlFor={obj.value}>{obj.label}</label>
			        </div>	  
				)
			}
			      
    	</React.Fragment>
	)

}

export default AdType;


 	