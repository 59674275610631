//libraries
import React, {useContext, useState, useEffect} from 'react';
import {FiltersContext} from '../../helpers/filters_context.js'
import spinnerInc from '../../images/icons/spinner-inc.svg'
import spinnerDec from '../../images/icons/spinner-dec.svg'

const Spinner = () => {  
	const filters = useContext(FiltersContext)
	const [counter, setCounter] = useState({count: 0})
	const [isMin, setIsMin] = useState(false)
	const [isMax, setIsMax] = useState(false)

	let bathrooms = filters.options.bathrooms
	let minBathrooms = filters.minBathrooms

	const setInitialState = () => {
		
			var i = 0
			i = parseFloat(minBathrooms)
			i = bathrooms.indexOf(i)

			if (i < 0) {
				setCounter({count: 0})
				filters.setMinBathrooms(bathrooms[0])
			} else {
				setCounter({count: i})
			}
	}

	useEffect(() => {
		setInitialState()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// handle reset of bathrooms filter 
	useEffect(() => {
		if (minBathrooms.length === 0) {
			setCounter({count: 0})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	const decrement = () => {
  		setCounter(prevState => {
  		if (!counter.count < 1) {
  			return {count:  prevState.count - 1}
  		} else {
  			return {count:  prevState.count}
  		}
      })
  	}

  	const increment = () => {
  		setCounter(prevState => {
        if (counter.count !== bathrooms.length - 1) {
  			return {count:  prevState.count + 1}
	  	} else {
			return {count:  prevState.count}
		}
      })
  	}

  	useEffect(() => {
  		filters.setMinBathrooms(bathrooms[counter.count])
  	// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, [counter])

  	// eslint-disable-next-line react-hooks/exhaustive-deps
  	useEffect(() => {
  		setIsMin(counter.count < 1 ? true : false)
  		setIsMax(counter.count === bathrooms.length - 1 ? true : false)
  	})

  	let spinner_dec = isMin ? 'spinner__btn spinner__btn--inactive' : 'spinner__btn'
  	let spinner_inc = isMax ? 'spinner__btn spinner__btn--inactive' : 'spinner__btn'

	return (
		<div className="spinner__wrap">
			<label className="input-label--big">
				Bathrooms
			</label>
			<div className="spinner spinner--fixed">
				<div className={spinner_dec} onClick={decrement}><img src={spinnerDec} alt="decrement" /></div>
					<span>{filters.minBathrooms}</span>
				<div className={spinner_inc} onClick={increment}><img src={spinnerInc} alt="increment" /></div>
	    	</div>
	    </div>
	)
}
export default Spinner;


 