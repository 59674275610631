import React, { useState } from 'react'
import close from '../../images/icons/close.svg'
import ViewTypeToggle from './view_type_toggle.js'

const SearchSettings = (props) => {  

  const [selectedOption, setSelectedOption] = useState([]);
  const showHideClassName = props.show ? "modal modal--show" : "modal modal--hide"

  const views = {
    simple: {name: 'Simple', value: 'simple'},
    detailed: {name: 'Detailed', value: 'detailed'}
  }

  return (
    <div className={showHideClassName}>
      <div className="modal__body">
        <section>

          <div className="layout--main">
            <header className="filters__header">
              <div className="link"></div>
              <div className="close modal__close" onClick={props.handleClose}>
                <img src={close} alt='Close btn' />
              </div>
            </header>

            <section className="section section--border bold">
              Search / Settings
            </section>
            <section className="section section--border">
              { Object.values(views).map((obj, i) => <ViewTypeToggle key={i} value={obj.value} name={obj.name} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />) }
            </section>
          </div>

        </section>
        
      </div> 
    </div>
  )
}

export default SearchSettings;
