

import React from 'react';

const Incentive = (props) =>  {

		if (props.listing.access_type_info) {
			return (
				<div>
				<div className="text--green--highlight">{props.listing.concession && props.listing.concession}</div>
				<div>{props.listing.access_type_info}</div>
				</div>
			)
		} else {
			return <div>N/A</div>
		}
		
}

export default Incentive;