import React, {useState, useEffect} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { mediaUrl } from '../../helpers/api_routes.js';
import ImgPlaceholder from '../../components/img_placeholder.js'

import SearchResultShowSlider from './search_result_show_slider'


const Images = (props) => {

	const listing = props.listing

	const [error, setError] = useState(false)

	let photos = listing.photos
	let photo = listing.photos.map(obj => obj)
	let featured = photo.filter(obj => obj.properties.featured > 0)
	featured = featured[0] && featured[0].path

	useEffect(() => {
		
	})

	if (photos.length > 0) {
		return (
			<React.Fragment>
				<div className={`single-result-header`}>
						<div className="slider__wrap">
							<SearchResultShowSlider photos={photos} galleryView={props.galleryView} />
						</div>
		        </div>
			</React.Fragment>
		)
	} if (photos.length === 1) {
		if (!error) {
			return <img src={`${mediaUrl}/${featured}`} alt='Featured' onError={() => setError(true)} />
		} else {
			return <ImgPlaceholder />	
		}
		
	} else {
		return <ImgPlaceholder />	
	}
	
}

export default Images;