import React from 'react';

const About = (props) => {

    const listing = props.listing
    
    return (
	    <p className="no-margin">
	          {listing.streets} <br />
	          Built in {listing.year_built}
	    </p>
    )
  }


export default About;