//libraries
import React from 'react';

function Info (props) {
    var bedrooms = props.listing.bedrooms;
    var isnum = /^\d+$/.test(bedrooms);

    return ( 

		<div className="item-list">
			{ !isnum ? <span>{props.listing.bedrooms}</span> : <span>{props.listing.bedrooms} bed</span>}
			{ props.listing.bathrooms !== 0 &&
				<span>{props.listing.bathrooms + ' bath'}</span>
			}
			{ props.listing.size !== 0 &&
				<span>{props.listing.size + ' sqft'}</span>
			}
		</div>

    );

}

export default Info;
