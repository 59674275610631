import React from 'react';

const ManagementInfo = (props) => {

		if (props.listing.management_company) {
			let management_company = Object.values(props.listing.management_company).map(obj => obj)
	 		let name = management_company[0]
	 		let address = management_company[1]
	 		let phone = management_company[2]
	 		let email = management_company[3]
	 		let fax = management_company[4]
	 		let website = management_company[5]
	 		let mailto = `mailto:${email}`

			return (
				<React.Fragment>
					<div className="semibold">{name}</div>
					<div>A: {address}</div>
					<div>P: {phone}</div>
					<div>E: <a href={mailto} className="text--primary">{email}</a></div>
					<div>F: {fax}</div>
					<div>W: <a href={website} className="text--primary">{website}</a></div>
				</React.Fragment>
			)
		
		} else {
			return <div>N/A</div>
		}
 		

		
	}


export default ManagementInfo;