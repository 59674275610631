//libraries
import React, { useState, useEffect, useContext } from 'react';
import "react-tabs/style/react-tabs.css";
import { searchListings, commonHeaders } from '../helpers/api_routes.js'
import SearchResultShowListing from '../components/search-result-show/index.js'
import {ViewContext} from '../helpers/view_context.js'

import Loader from '../components/loader.js'

const axios = require('axios');

function SearchResultShow (props, {location}) {

    const viewContext = useContext(ViewContext)
    const [locationKey, setLocationKey] = useState(0)
    const [listing, setListing] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    if (viewContext.nav) {
      viewContext.setNav(!viewContext.nav)
    }

    useEffect(() => {
        if (location) {
          setLocationKey(location.key)
        }
    }, [location]);

    const user = props.user;
    const headers = {...commonHeaders , ...{"Adm-Session-ID": user.sessionId} }

    const fetchListing = async () => {
      setIsLoading(true)
      //
      const result = await axios(`${searchListings}/${props.match.params.id}`, {headers: headers});
      setListing(result);
      //
      setIsLoading(false)
    };
    
    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        fetchListing()
      }
      return () => isSubscribed = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
      <React.Fragment>
        { isLoading ? 
          <Loader /> :
          <SearchResultShowListing key={locationKey} listing={listing} /> 
        }
      </React.Fragment>
    )  

}

export default SearchResultShow;
