import React from 'react';

 const Amenities = (props) => {

		const listing = props.listing;

		if (listing.apartment_amenities) {
			return (
				
					<ul>
					  { listing.apartment_amenities.map((item,i) => <li key={i}>{item}</li>) }
					</ul>
			
			)
		} else {
			return <div>No apartment amenities</div>
		}

		
	}


export default Amenities;