//libraries
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SearchResultSimple from './search-components/search_result_simple.js'
import SearchResultDetailed from './search-components/search_result_detailed.js'
import {FiltersContext} from '../../helpers/filters_context.js'



function SearchResult (props) {

    const filters = useContext(FiltersContext)
    // set state 
    var view = filters.detailedResults

    if (props.isLoading) {
        return null
    }
    if (view === "detailed") {
        return (
 
            <Link to={`/listing/${props.id}`} key={props.id} >
                <SearchResultDetailed listing={props.listing} /> 
            </Link>
       
        )
    } else {
        return (
            <Link to={`/listing/${props.id}`} key={props.id} >
                <SearchResultSimple listing={props.listing} />
            </Link>
        )
    }
}

export default SearchResult;
